import { useMemo } from 'react'

import { IoIosWarning } from 'react-icons/io'

import { Box } from '../../components/Box/Box'
import { HeaderDate, HeaderIcon, HeaderText, HeaderTitle, HeaderYear, PageHeader } from '../../components/PageHeader/PageHeader'
import { ReactComponent as ReuniaoPublicaIcon } from '../../icons/reuniao-publica.svg'
import { formatYearMonth, getYearMonthFromDateRange } from '../../utils/date'
import { BottomInfo } from './styles'
import { Data } from './types'
import { ReuniaoPublicaWeek } from './Week'

interface MonthProps {
  weeks: Data['weeks']
}

export function Month({ weeks }: MonthProps) {
  const yearMonth = useMemo(() => {
    const [start, ...dates] = weeks.map(week => Array.isArray(week.date) ? week.date[0] : week.date)
    const end = dates.pop() ?? start
    return getYearMonthFromDateRange(start, end)
  }, [weeks])

  const [year, month] = useMemo(() => formatYearMonth(yearMonth), [yearMonth])

  return (
    <Box fill>
      <PageHeader bg="/header/reuniao-publica.jpg">
        <HeaderIcon mergeWithPaper>
          <ReuniaoPublicaIcon />
        </HeaderIcon>
        <HeaderText>
          <HeaderTitle>
            Discurso Público e<br />
            A Sentinela
          </HeaderTitle>
          <HeaderDate>
            {month}<br />
            <HeaderYear>{year}</HeaderYear>
          </HeaderDate>
        </HeaderText>
      </PageHeader>

      {weeks.map(week => (
        <ReuniaoPublicaWeek
          key={week.date.toISOString()}
          week={week}
          mainYearMonth={yearMonth}
        />
      ))}

      <BottomInfo>
        <strong><IoIosWarning /> Atenção:</strong> Caso o orador fique até o final da reunião, este fará a oração final.
      </BottomInfo>
    </Box>
  )
}
